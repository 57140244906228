function Badge({ colour, text }) {
  const colours = {
    alliance: "bg-alliance-1 text-alliance-5 ring-alliance-5/10",
    rikki: "bg-rikki-1 text-rikki-5 ring-rikki-5/10",
    ae: "bg-ae-1 text-ae-5 ring-ae-5",
    megan: "bg-megan-1 text-megan-5 ring-megan-5/10",
    tzu: "bg-tzu-1 text-tzu-5 ring-tzu-5/10",
    lyfii: "bg-lyfii-1 text-lyfii-5 ring-lyfii-5/10",
    lia: "bg-lia-1 text-lia-5 ring-lia-5/10",
    baas: "bg-baas-1 text-baas-5 ring-baas-5/10",
    fubuki: "bg-fubuki-1 text-fubuki-5 ring-fubuki-5/10",
    warning: "bg-warning-1 text-warning-5 ring-warning-5/10",
    destruct: "bg-destruct-1 text-destruct-5 ring-destruct-5/10",
    x4: "bg-x4-1 text-x4-5 ring-x4-5/10",
    ego_dlc_split: "bg-sv-1 text-sv-5 ring-sv-5/10",
    ego_dlc_terran: "bg-coh-1 text-coh-5 ring-coh-5/10",
    ego_dlc_pirate: "bg-toa-1 text-toa-5 ring-toa-5/10",
    ego_dlc_boron: "bg-ke-1 text-ke-5 ring-ke-5/10",
    ego_dlc_timelines: "bg-tl-1 text-tl-5 ring-tl-5/10",
    ego_dlc_ventures: "bg-megan-1 text-megan-5 ring-megan-5/10",
  };

  var classes = `justify-center inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-2 ring-inset ${colours[colour]}`;

  return <span className={classes}>{text}</span>;
}

export default Badge;
