import Sidebar from "./Components/Sidebar";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <div className="w-32">
        <Sidebar />
      </div>
      <Outlet />
    </>
  );
}
