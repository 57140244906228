import "@preact/signals-react/auto";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Layout";
import Styleguide from "./Pages/Styleguide";
import LIAGrid from "./Pages/LIAGrid";
import Home from "./Pages/Home";
import X4 from "./Pages/X4";

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/x4-dashboard/*" element={<X4 />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/styleguide" element={<Styleguide />} />
          <Route path="/lia-grid" element={<LIAGrid />} />
          <Route path="/x4-dashboard" element={<X4 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
