import { signal } from "@preact/signals-react";
import ReactHtmlParser from "react-html-parser";

const time = signal(
  formatDate(new Date(Date.now()), "YYYYMMDD") +
    "<br>" +
    formatDate(new Date(Date.now()), "THHmmssZ"),
);

function Clock() {
  return (
    <div className="fixed bottom-0 w-32 text-center font-bold text-megan-5">
      {ReactHtmlParser(time.value)}
    </div>
  );
}

function Time() {
  time.value =
    formatDate(new Date(Date.now()), "YYYYMMDD") +
    "<br>" +
    formatDate(new Date(Date.now()), "THHmmssZ");
  window.setTimeout(Time, 1000);
}
window.onload = Time;

export function formatDate(date, format) {
  if (date instanceof Date) {
    const map = {
      MM: (date.getMonth() + 1).toString().padStart(2, 0),
      DD: date.getDate().toString().padStart(2, 0),
      YYYY: date.getFullYear(),
      HH: date.getUTCHours().toString().padStart(2, 0),
      mm: date.getUTCMinutes().toString().padStart(2, 0),
      ss: date.getUTCSeconds().toString().padStart(2, 0),
    };
    return format.replace(/MM|DD|YYYY|HH|mm|ss/gi, (matched) => map[matched]);
  }
}

export default Clock;
