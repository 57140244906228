import { NavLink } from "react-router-dom";

function NavListItems({ destinations, liClasses, linkClasses }) {
  return destinations.map((destination) => {
    if (destination.kids) {
      console.log(destination.kids);
      var kidList = Array.from(destination.kids).map((kid) => {
        return <li>{kid}</li>;
      });
      return (
        <>
          <li className={liClasses}>
            <NavLink to={destination.url} className={linkClasses}>
              {destination.title}
            </NavLink>
          </li>
          <ul>{kidList}</ul>
        </>
      );
    } else {
      return (
        <li className={liClasses}>
          <NavLink to={destination.url} className={linkClasses}>
            {destination.title}
          </NavLink>
        </li>
      );
    }
  });
}

function NavList({
  destinations,
  navClasses,
  ulClasses,
  liClasses,
  linkClasses,
}) {
  return (
    <nav className={navClasses}>
      <ul className={ulClasses}>
        <NavListItems
          destinations={destinations}
          liClasses={liClasses}
          linkClasses={linkClasses}
        />
      </ul>
    </nav>
  );
}

export default NavList;
