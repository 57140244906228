import { error } from "./FileUploadInput";

function Error() {
  return (
    error.value &&
    error.value.error && (
      <div className="mx-auto mt-2 basis-3/4 rounded-md bg-destruct-1 p-2 text-center font-bold text-destruct-5 ring-4 ring-destruct-5">
        {error.value.error}
      </div>
    )
  );
}

export default Error;
