import Grid from "../Components/Grid";

const gridList = [
  "IU",
  "Tae",
  "E",
  "Rikki",
  "Ae",
  "Megan",
  "Tzu",
  "Lyfii",
  "Lia",
  "BAAS",
  "Fubuki",
  "Kongou",
  "Shizuku",
  "Emilia",
  "Krystal",
  "Lyni",
];

/*const personalColours = {
    rikki: "text-rikki-5"
    ae: "text-ae-5",
    megan: "text-megan-5",
    tzu: "text-tzu-5",
    lyfii: "text-lyfii-5",
    lia: "text-lia-5",
    baas: "text-baas-5",
    fubuki: "text-fubuki-5"
};*/

function GridItems() {
  return gridList.map((item) => {
    var colourClass = `text-center text-${item.toLowerCase()}-5`;
    return <div className={colourClass}>{item}</div>;
  });
}

function LIAGrid() {
  return (
    <Grid
      items={<GridItems />}
      classes="mx-auto flex flex-wrap my-auto grid-cols-4 gap-4"
    />
  );
}

export default LIAGrid;
