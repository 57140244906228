import Badge from "./Badge";
import Grid from "./Grid";
import NavList from "./NavList";
import { formatDate } from "./Clock";
import { save } from "./FileUploadInput";

function Topbar() {
  return (
    <div className="ml-2 text-alliance-5">
      <VeryTopbar />
      <SaveChildren />
    </div>
  );
}

function VeryTopbar() {
  var saveDetails = save.value["info"]["save"]["attr"];
  var game = save.value["info"]["game"]["attr"];
  var player = save.value["info"]["player"]["attr"];
  var patches = Object.values(save.value["info"]["patches"]["patch"]);
  var patchBadges = patches.map((patch) => {
    return (
      <Badge
        colour={patch["attr"]["$extension"]}
        text={patch["attr"]["$name"]}
      />
    );
  });

  return (
    <div className="flex justify-between p-2">
      <div>
        <h1 className="text-2xl font-bold">{player["$name"]}</h1>
        {player["$location"]} {player["$money"]}Cr.
      </div>
      <div>
        {saveDetails["name"]}{" "}
        {formatDate(
          new Date(parseInt(saveDetails["$date"]) * 1000),
          "YYYYMMDDTHHmmssZ",
        )}
        <br />
        {game["$id"]} {Number(parseInt(game["$version"]) / 100).toFixed(2)}{" "}
        {game["$start"]} ({game["$seed"]})
      </div>
      <div>
        <Grid items={patchBadges} classes="grid grid-cols-3 gap-2" />
      </div>
    </div>
  );
}

function SaveChildren() {
  var destinations = Object.keys(save.value).map((saveChild) => {
    const obj = {};
    obj.title = saveChild.charAt(0).toUpperCase() + saveChild.slice(1);
    obj.url = saveChild;
    obj.kids = Object.keys(save.value[saveChild]);
    return obj;
  });
  return (
    <div className="h-7 bg-alliance-5">
      <NavList
        destinations={destinations}
        ulClasses="flex justify-around"
        liClasses="hover:text-lia-3 text-megan-5 [&_.active]:text-lia-5 [&_.active]:bg-gradient-to-t from-alliance-2 to-alliance-2 via-alliance-3 contents [&_.active]:border-b-2 [&_.active]:border-t-2 [&_.active]:border-lia-5 basis-full text-center"
        linkClasses="hover:bg-gradient-to-t from-alliance-2 to-alliance-2 via-alliance-3 w-full h-7"
      />
    </div>
  );
}

export default Topbar;
