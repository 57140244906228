import React from "react";
import Topbar from "../Components/Topbar";
import Dashboard from "../Components/Dashboard";
import FileUploadInput, { save } from "../Components/FileUploadInput";

function X4() {
  var classes = save.value ? "basis-full" : "mx-auto flex flex-wrap";
  return (
    <div className={classes}>
      {save.value && <Topbar />}
      <FileUploadInput />
      <Dashboard />
    </div>
  );
}

export default X4;
