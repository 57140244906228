import { save, error } from "./FileUploadInput";
import Error from "./Error";
import Warning from "./Warning";

function Dashboard() {
  return (
    <>
      {!save.value && <NoFile />}
      {save.value && <Overview />}
      <Error />
      <Warning />
    </>
  );
}

function NoFile() {
  error.value = { error: "You haven't uploaded a savefile yet :(" };
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="icon-document-notes"
      >
        <path
          class="fill-megan-5"
          d="M6 2h6v6c0 1.1.9 2 2 2h6v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2zm2 11a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2H8zm0 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H8z"
        ></path>
        <polygon class="fill-megan-2" points="14 2 20 8 14 8"></polygon>
        <circle
          class="fill-destruct-5"
          r="5.03247"
          cy="18.26623"
          cx="5.66883"
        />
        <path
          d="m6.37841,18.26623l1.42419,1.64471a0.50325,0.58117 0 0 1 -0.70958,0.81945l-1.42419,-1.64471l-1.42419,1.64471a0.50325,0.58117 0 1 1 -0.70958,-0.81945l1.42419,-1.64471l-1.42419,-1.64471a0.50325,0.58117 0 0 1 0.70958,-0.81945l1.42419,1.64471l1.42419,-1.64471a0.50325,0.58117 0 0 1 0.70958,0.81945l-1.42419,1.64471z"
          class="fill-destruct-2"
        />
      </svg>
    </>
  );
}

function Overview() {
  var universe = save.value["universe"];
  var universeChildren = Object.keys(universe);
  var info = universeChildren.map((universeChild) => {
    var childCount =
      universeChild.endsWith("s") && universeChild !== "physics"
        ? Object.keys(universe[universeChild][universeChild.slice(0, -1)])
            .length
        : Object.keys(universe[universeChild]).length;
    return (
      <div className="pb-2 text-center text-megan-5">
        <h1 className="text-6xl font-bold">{childCount}</h1>
        <button
          onClick={`location.href='${universeChild}'`}
          className="items-center rounded-md border-0 bg-alliance-7 px-2 py-1.5 font-bold text-megan-5 shadow-[0_2px_0_theme(colors.alliance.9),inset_0_2px_2px_theme(colors.alliance.1)] sm:text-sm sm:leading-6"
        >
          {universeChild.charAt(0).toUpperCase() + universeChild.slice(1)}
        </button>
      </div>
    );
  });

  return <div className="mt-8 flex justify-around bg-alliance-5">{info}</div>;
}

export default Dashboard;
