import { error } from "./FileUploadInput";

function Warning() {
  return (
    error.value &&
    error.value.warning && (
      <div className="mx-auto mt-2 basis-3/4 rounded-md bg-warning-1 p-2 text-center font-bold text-warning-5 ring-4 ring-warning-5">
        {error.value.warning}
      </div>
    )
  );
}

export default Warning;
