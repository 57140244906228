import Grid from "../Components/Grid";

const colours = [
  "alliance",
  "rikki",
  "ae",
  "megan",
  "tzu",
  "lyfii",
  "lia",
  "baas",
  "fubuki",
  "warning",
  "destruct",
  "x4",
  "sv",
  "coh",
  "toa",
  "ke",
  "tl",
];

/*const personalColours = {
    alliance: "bg-alliance-1", "bg-alliance-2", "bg-alliance-3", "bg-alliance-4", "bg-alliance-5", "bg-alliance-6", "bg-alliance-7","bg-alliance-8", "bg-alliance-9",  
    rikki: "bg-rikki-1", "bg-rikki-2", "bg-rikki-3", "bg-rikki-4", "bg-rikki-5", "bg-rikki-6", "bg-rikki-7", "bg-rikki-8", "bg-rikki-9
    ae: "bg-ae-1", "bg-ae-2", "bg-ae-3", "bg-ae-4", "bg-ae-5", "bg-ae-6", "bg-ae-7", "bg-ae-8", "bg-ae-9",
    megan: "bg-megan-1", "bg-megan-2", "bg-megan-3", "bg-megan-4", "bg-megan-5", "bg-megan-6", "bg-megan-7", "bg-megan-8", "bg-megan-9",
    tzu: "bg-tzu-1", "bg-tzu-2", "bg-tzu-3", "bg-tzu-4", "bg-tzu-5", "bg-tzu-6", "bg-tzu-7", "bg-tzu-8", "bg-tzu-9",
    lyfii: "bg-lyfii-1", "bg-lyfii-2", "bg-lyfii-3", "bg-lyfii-4", "bg-lyfii-5", "bg-lyfii-6", "bg-lyfii-7", "bg-lyfii-8", "bg-lyfii-9",
    lia: "bg-lia-1", "bg-lia-2", "bg-lia-3", "bg-lia-4", "bg-lia-5", "bg-lia-6", "bg-lia-7", "bg-lia-8", "bg-lia-9",
    baas: "bg-baas-1", "bg-baas-2", "bg-baas-3", "bg-baas-4", "bg-baas-5", "bg-baas-6", "bg-baas-7", "bg-baas-8", "bg-baas-9",
    fubuki: "bg-fubuki-1", "bg-fubuki-2", "bg-fubuki-3", "bg-fubuki-4", "bg-fubuki-5", "bg-fubuki-6", "bg-fubuki-7", "bg-fubuki-8", "bg-fubuki-9"
    warning: "bg-warning-1", "bg-warning-2", "bg-warning-3", "bg-warning-4", "bg-warning-5", "bg-warning-6", "bg-warning-7", "bg-warning-8", "bg-warning-9"
    destruct: "bg-destruct-1", "bg-destruct-2", "bg-destruct-3", "bg-destruct-4", "bg-destruct-5", "bg-destruct-6", "bg-destruct-7", "bg-destruct-8", "bg-destruct-9"
    x4: "bg-x4-1", "bg-x4-2", "bg-x4-3", "bg-x4-4", "bg-x4-5", "bg-x4-6", "bg-x4-7", "bg-x4-8", "bg-x4-9",
    sv: "bg-sv-1", "bg-sv-2", "bg-sv-3", "bg-sv-4", "bg-sv-5", "bg-sv-6", "bg-sv-7", "bg-sv-8", "bg-sv-9",
    coh: "bg-coh-1", "bg-coh-2", "bg-coh-3", "bg-coh-4", "bg-coh-5", "bg-coh-6", "bg-coh-7", "bg-coh-8", "bg-coh-9",
    toa: "bg-toa-1", "bg-toa-2", "bg-toa-3", "bg-toa-4", "bg-toa-5", "bg-toa-6", "bg-toa-7", "bg-toa-8", "bg-toa-9",
    ke: "bg-ke-1", "bg-ke-2", "bg-ke-3", "bg-ke-4", "bg-ke-5", "bg-ke-6", "bg-ke-7", "bg-ke-8", "bg-ke-9",
    tl: "bg-tl-1", "bg-tl-2", "bg-tl-3", "bg-tl-4", "bg-tl-5", "bg-tl-6", "bg-tl-7", "bg-tl-8", "bg-tl-9",
};*/

function ColourDivs() {
  return colours
    .map((colour) => {
      return [
        `bg-${colour}-1`,
        `bg-${colour}-2`,
        `bg-${colour}-3`,
        `bg-${colour}-4`,
        `bg-${colour}-5`,
        `bg-${colour}-6`,
        `bg-${colour}-7`,
        `bg-${colour}-8`,
        `bg-${colour}-9`,
      ];
    })
    .flat()
    .map((colour) => {
      var colourClass = `text-center p-4 ${colour}`;
      return <div className={colourClass}>{colour}</div>;
    });
}

function Styleguide() {
  return (
    <Grid
      items={<ColourDivs />}
      classes="mx-auto flex flex-wrap my-16 grid-cols-1 sm:grid-cols-9 gap-y-4 gap-x-8"
    />
  );
}

export default Styleguide;
