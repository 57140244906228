import { parse } from "arraybuffer-xml-parser";
import { signal, effect } from "@preact/signals-react";
import { formatDate } from "./Clock";

export const save = signal();
export const error = signal();
const file = signal();
const inputText = signal("Upload your savefile");
const reading = signal(false);
/*
effect(async() => {
  if(file.value){
    console.log(file.value.name);
    await fetch("https://eliisa.19thfleet.org/api/convertsave", {
      method: "POST",
      headers: {
        "Content-Type": "application/xml",
      },
      body: file.value,
    })
      .then((data) => {
        console.log("also here");
        data.json().then(body2 => console.log(body2));
      })
      .catch((e) => {
        console.log("and here");
        console.log(e);
      });
  }
});*/

async function onFileUpload(event) {
  error.value = {};
  console.time("Reading Save");
  reading.value = true;
  file.value = event.target.files[0];

  inputText.value = file.value.name + " (" + file.value.size + "B)";
  var reader = new FileReader();
  reader.onload = function (e) {
    console.log(this.result);
    var json = parse(this.result, {
      attributesNamePrefix: "",
      ignoreNameSpace: true,
      attributesNodeName: "attr",
    });
    save.value = json["savegame"];
    console.log(save.value);
    reading.value = false;
    console.timeEnd("Reading Save");
  };
  reader.readAsArrayBuffer(file.value);
}

function FileUploadInput() {
  var margin = save.value ? "ml-[40vw]" : "mt-24";
  var marginClasses = `mx-auto ${margin}`;

  return (
    <div className={marginClasses}>
      <form>
        <input
          id="savefile"
          type="file"
          accept=".xml"
          onChange={onFileUpload}
          hidden
        ></input>
        <div className="mt-2">
          <label
            htmlFor="savefile"
            className="flex max-w-fit items-center rounded-md border-0 bg-alliance-5 py-1.5 pl-7 pr-20 text-megan-5 shadow-[0_4px_0_theme(colors.alliance.1),inset_0_4px_4px_theme(colors.alliance.9)] sm:text-sm sm:leading-6"
          >
            <Button />
          </label>
        </div>
      </form>
    </div>
  );
}

function Button() {
  if (reading.value) {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="icon-cog mr-4 w-8 animate-spin-slow"
        >
          <path
            class="fill-megan-5"
            d="M6.8 3.45c.87-.52 1.82-.92 2.83-1.17a2.5 2.5 0 0 0 4.74 0c1.01.25 1.96.65 2.82 1.17a2.5 2.5 0 0 0 3.36 3.36c.52.86.92 1.8 1.17 2.82a2.5 2.5 0 0 0 0 4.74c-.25 1.01-.65 1.96-1.17 2.82a2.5 2.5 0 0 0-3.36 3.36c-.86.52-1.8.92-2.82 1.17a2.5 2.5 0 0 0-4.74 0c-1.01-.25-1.96-.65-2.82-1.17a2.5 2.5 0 0 0-3.36-3.36 9.94 9.94 0 0 1-1.17-2.82 2.5 2.5 0 0 0 0-4.74c.25-1.01.65-1.96 1.17-2.82a2.5 2.5 0 0 0 3.36-3.36zM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
          ></path>
          <circle cx="12" cy="12" r="2" class="fill-megan-2"></circle>
        </svg>
        <span>Reading...</span>
      </>
    );
  } else {
    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="icon-document-notes mr-4 w-8"
        >
          <path
            class="fill-megan-5"
            d="M6 2h6v6c0 1.1.9 2 2 2h6v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2zm2 11a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2H8zm0 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H8z"
          ></path>
          <polygon class="fill-megan-2" points="14 2 20 8 14 8"></polygon>
        </svg>
        <span>{inputText.value}</span>
      </>
    );
  }
}

export default FileUploadInput;
